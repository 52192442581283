/**
 * .c-header
 */

$component: 'header';
#{'.' + $global-components-namespace + $component} {

    @extend %u-flex;
    @extend %u-jc-space-between;
    @extend %u-ai-center;

    padding: 4vmax;
    
    filter: drop-shadow(0 1px 2px black);
    
    &__logo {
        > * {
            height: 4rem;
            aspect-ratio: 137 / 64;
        }
    }

    svg {
        width: auto;
    }

    ul {
        @extend %u-flex;
        @extend %u-ai-center;
    }

    @media (max-width: 600px) {
        &__icons {
            display: none !important;
        }
    }

    
    @media (max-width: 979px) {
        &__nav {
            order: 3;

            ul {
                display: none !important;
            }

            &__icons {
                margin-left: 0;
            }
        }

        &__icons {
            margin-left: auto;
            margin-right: 2rem;
        }
    }

    @media (min-width: 980px) {

        &__nav {

            width: 50%;

            button {
                display: none;
            }

            ul {
                gap: 2vmax;
                justify-content: center;
            }

        }

    }

    &__nav li,
    &__nav button {
        font-family: 'helvetica neue', 'helvetica', sans-serif;
        font-weight: 300;
        letter-spacing: .22em;
        font-size: 17px;
        text-transform: uppercase;
    }

    &__nav a {
        transition: opacity .4s;
    }

    &__nav a:not([aria-current]):not(:hover):not(:focus) {
        opacity: .6;
    }

    button {
        font-weight: 500;
    }

    &__icons {
        // @extend %u-jc-space-between;
        gap: 1rem;

        a {
            @extend %u-block;
        }

        svg {
            @extend %u-block;
            height: 2rem;
        }

        @media (min-width: 980px) {

            gap: 2rem;

        }

    }

}