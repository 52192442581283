/**
 * .c-media-grid
 */

$component: 'media-grid';
#{'.' + $global-components-namespace + $component} {

    .fotorama__nav-wrap {
        margin-top: 2rem;
    }

    .fotorama__img {

    }

}
