/**
 * .c-blockquote
 */

$component: 'blockquote';
#{'.' + $global-components-namespace + $component} {

    position: relative;
    margin: 2rem 0 0;
    padding: 2rem 0 0;

    > * + * {
        margin-top: 1rem;
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        @extend %u-family-big-caslon;
        transform: scale(1);
        opacity: .2;
        content: url('/src/svg/quotemark.svg');
        aspect-ratio: 64 / 54;
        overflow: hidden;
        transform: translateX(-50%) translateY(-75%);
        z-index: -1;

    }

}