/**
 * .s-article
 */
    
$scoped: 'article';
#{'.' + $global-scoped-namespace + $scoped} {

    line-height: 1.4;

    > * + * {
        margin-top: 1rem;
    }

    * + h2,
    * + h3 {
        margin-top: 3rem;
    }

    * + h4,
    * + h5 {
        margin-top: 2rem;
    }

    h1 {

    }

    h2 {
        font-size: 3.6rem;

    }

    h3 {
        font-size: 2.2rem;
    }

    h4 {
        font-size: 1.6rem;
        font-weight: 500;
    }

    h5 {
        font-size: 1.3rem;
        font-weight: 500;
    }
    
    li {
        list-style-type: disc;
        list-style-position: inside;

        + li {
            margin-top: .5em;
        }
    }

    * a {
        color: mix($color-washed-rose, $color-soft-charcoal, 50%);
        text-decoration: underline;
    }
}