/**
 * .c-icon-list
 */

$component: 'icon-list';
#{'.' + $global-components-namespace + $component} {

    &__item {
        list-style: none;
        list-style-type: none;

        & + & {
            margin-top: .5rem;
        }
    }

    &__link {
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    svg {
        flex-shrink: 0;
    }

    + * {
        margin-top: 4rem;
    }

}