/**
 * .o-type
 */

$object: 'type';
#{'.' + $global-objects-namespace + $object} {
    &-h0,
    &-h1,
    &-h2 {
        // @extend %u-weight-bold;
    }
    &-h0 {
        font-size: clamp(28px, 4vw, 50px);
        line-height: calc(59/50);
    }
    &-h1 {
        font-size: clamp(22px, 4vw, 34px);
    }
    &-h2 {
        font-size: clamp(19px, 4vw, 27px);
    }
    &-h3 {
        font-size: clamp(18px, 4vw, 24px);
    }
    &-body {
        font-size: clamp(16px, 4vw, 18px);
    }
    &-16px {
        font-size: 16px;
    }
}