/**
 * .c-home
 */

 $component: 'home';
 #{'.' + $global-components-namespace + $component} {
   display: grid;
   min-height: 100dvh;
   max-height: 100dvh;
   grid-template-columns: 1fr;
   grid-template-rows: 1fr;
   color: white;
   overflow: hidden;

   > * {
      grid-area: 1 / 1;
      min-height: 100dvh;
   }

   &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 0 4vmax;
   }

   main {
      // margin-top: auto;
      text-align: center;
      filter: drop-shadow(0 1px 2px black);
      display: flex;
      flex-direction: column;
      gap: 1rem;
   }

   h1 {
      font-size: 33px;
      font-family: 'Big Caslon CC', sans-serif;
      font-weight: normal;
      letter-spacing: 0.045em;
      text-transform: uppercase;
   }

   h2 {
      text-transform: uppercase;
      font-family: 'helvetica neue', 'helvetica', sans-serif;
      font-weight: 300;
      letter-spacing: .25em;
      font-size: 14px;
   }

   @media (min-width: 800px) {
      h1 {
         font-size: 70px;
      }

      h2 {
         font-size: 17px;
      }
   }
 }
