@font-face {
    font-family: 'Big Caslon CC';
    src: url('/src/font/big-caslon-cc/big_caslon_cc_black_italic-webfont.woff2') format('woff2'),
         url('/src/font/big-caslon-cc/big_caslon_cc_black_italic-webfont.woff') format('woff');
    font-weight: 900;
    font-style: italic;

}

@font-face {
    font-family: 'Big Caslon CC';
    src: url('/src/font/big-caslon-cc/big_caslon_cc_black-webfont.woff2') format('woff2'),
         url('/src/font/big-caslon-cc/big_caslon_cc_black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;

}

@font-face {
    font-family: 'Big Caslon CC';
    src: url('/src/font/big-caslon-cc/big_caslon_cc_bold_italic-webfont.woff2') format('woff2'),
         url('/src/font/big-caslon-cc/big_caslon_cc_bold_italic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;

}

@font-face {
    font-family: 'Big Caslon CC';
    src: url('/src/font/big-caslon-cc/big_caslon_cc_bold-webfont.woff2') format('woff2'),
         url('/src/font/big-caslon-cc/big_caslon_cc_bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'Big Caslon CC';
    src: url('/src/font/big-caslon-cc/big_caslon_cc_italic-webfont.woff2') format('woff2'),
         url('/src/font/big-caslon-cc/big_caslon_cc_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'Big Caslon CC';
    src: url('/src/font/big-caslon-cc/big_caslon_cc_regular-webfont.woff2') format('woff2'),
         url('/src/font/big-caslon-cc/big_caslon_cc_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

body {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
    Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 19px;
}