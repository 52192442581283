/**
 * .o-container
 */

$object: 'container';

#{'.' + $global-objects-namespace + $object} {
  @extend .u-mh-auto;
  width: 80vw;
  max-width: 1440px;
}

/**
 * .o-2col-a
 */

 $object: '2col-a';

 #{'.' + $global-objects-namespace + $object} {
  @extend .u-flex;
  flex-direction: column;
  gap: 3rem;

  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;

    &__side {
      width: 40%;
    }

    &__main {
      width: 50%;
    }

    &__image {
      img {
        width: 100%;
      }
    }

    &__text {
      padding-top: 4rem;
    }

    &--reverse {
      flex-direction: row-reverse;
    }
  }
 }