/**
 * .c-menu-overlay
 */

 $component: 'menu-overlay';
 #{'.' + $global-components-namespace + $component} {

    position: fixed;
    inset: 0;
    z-index: 99;
    background-color: rgba(#1B1818, .9);
    padding: 8vmin;
    color: white;
    font-size: 7vw;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    

    button {
        margin-left: auto;
        font-size: 60%;
        text-transform: uppercase;
        margin-bottom: 6vh;
    }

    ul > * + *  {

        margin-top: 2rem;

    }

 }
