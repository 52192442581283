/**
 * .c-background-photo
 */

 $component: 'background-photo';
 #{'.' + $global-components-namespace + $component} {
   overflow: hidden;
   background-color: #1B1818;
   background-size: cover;
   background-position: center;

   .c-bio & {
      height: calc(44vmax);
      max-height: 70vh;
   }

   img {
      position: relative;
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: 0;
      animation: fadein 2s;
      animation-fill-mode: forwards;
   }

   &:after {
      inset: 0;
      position: absolute;
      z-index: 1;
      content: '';
      background: radial-gradient(87.22% 50% at 50% 50%, rgba(27, 24, 24, 0.00) 0%, rgba(27, 24, 24, 0.00) 50%, rgba(27, 24, 24, 0.80) 88.54%);
   }
}

 @keyframes fadein {
   from { opacity: 0; }
   to   { opacity: 1; }
}