// Global color variables
$color-white: #FFFFFF;
$color-off: #F8F8F9;
$color-matte: #7E8B98;

// Olivia
$color-washed-rose: #F4D2D6;
$color-soft-charcoal: #303030;
$color-soft-charcoal-darker: #1B1818;

// Mass
$color-ultramarine: #261299;
$color-grape: #6D1E6F;
$color-crimson: #BF2B4B;
$color-scarlet: #F9372B;
$color-charkoal: #11093D;


// Auto Colors
$auto-colors: (
  'white': $color-white,
  'matte': $color-matte,
  'off': $color-off,

  'ultramarine': $color-ultramarine,
  'grape': $color-grape,
  'crimson': $color-crimson,
  'scarlet': $color-scarlet,
  'charkoal': $color-charkoal
);


// Make color variables in @root
// var(--color…)

$vars-colors: (
  'white': $color-white,
  'charkoal': $color-charkoal,
  'matte': $color-matte,
  'washed-rose': $color-washed-rose,
  'off': $color-off
);

body {
  @each $alias, $color in $vars-colors {
    --color-#{$alias}: #{$color};
  };
}