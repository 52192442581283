@include make-utility(( alias: 'mr-auto', class: true, margin-right: auto));
@include make-utility(( alias: 'mh-auto', class: true, margin-left: auto, margin-right: auto));
@include make-utility(( alias: 'mv-auto', class: true, margin-top: auto, margin-bottom: auto));

.u-m-0 {
    margin: 0;
}

.u-m-children-0>* {
    margin: 0;
}

.u-mt-siblings-s>*+* {
    margin-top: 1rem;
}

.u-mt-siblings-m>*+* {
    margin-top: 3rem;
}

.u-mt-siblings-l>*+* {
    margin-top: 6rem;
}

.u-mt-children-xl>* {
    margin-top: 7.5rem;
}

.u-gap-1 {
    gap: 1rem;
}