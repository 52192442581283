/**
 * .c-bio
 */

 $component: 'bio';
 #{'.' + $global-components-namespace + $component} {

    .c-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 20;
        color: white;
    }

    padding-bottom: 8vmax;
   
 }
